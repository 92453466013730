// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Franchise | Learning Wings Education System',
  nakedPageSlug: 'franchise',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Franchise">
        Franchise
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          Learning Wings has a proven support system in place to provide a solid
          methodical way to start up a new school as per the international
          standards. Learning Wings provides end-to-end turnkey consulting
          support at every step while building a new school. It extends it’s
          support in all core areas of school operations and ensures that you've
          achieved your dream school. Here's what we do...
        </p>
        <h2 className="mask-h4">Consulting Services</h2>
        <p>
          Learning Wings' partner schools provide children and youth with
          original and unique educational enrichment programmes that bring out
          the very best in each child through fun and encouragement, while
          upholding the highest standards of educational excellence. Towards
          this end Learning Wings provides end-to-end turnkey consulting
          services.
        </p>
        <h2 className="mask-h4">Human Resources Management</h2>
        <p>
          Human Resources are the most crucial element of a school management
          system. Learning Wings shares with you an extensive know-how in the
          areas of HR management.
        </p>
        <h2 className="mask-h4">Multiple Curricula</h2>
        <p>
          Learning Wings Education Systems offers multiple curricula to its
          affiliate schools and Learning Centres. Learning Wings provides
          dedicated teachers training services to ensure high quality of
          teaching.
        </p>
        <h2 className="mask-h4">Infrastructure</h2>
        <p>
          Learning Wings provides in-depth information on developing a layout
          plan for a school project. If the plan is already under
          implementation, many corrections can be done to achieve an efficient
          design of a school in terms of an ambiance, efficient utilisation of
          space, computer networking, safety, implementation of ISO 14001,
          disaster management and many more specific requirements prescribed by
          the educational boards with which the school intends to associate and
          obtain affiliations. All such mandatory requirements should be carried
          out right in the infrastructure development stage. Learning Wings
          guides you through the myriads of such requirements and needs.
        </p>
        <h2 className="mask-h4">Assets Management</h2>
        <p>
          Learning Wings extends you a helping hand while acquiring assets for
          the school. Learning Wings can also provide support with sources and
          procurement procedures.
        </p>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
